import ConversationApi from '@/apis/conversation.api';
import {Status} from '@/helpers/constants';
import InstructionModel from '@/models/instruction.model';
import ConversationService from '@/services/conversation.service';
import AnswerModel from '@/models/answer.model';
import createAnswer from '@/factories/answer.factory';
import encodePayload from '@/encoder/reply.payload.encoder';
import InstructionAdvancer from '@/interfaces/chat/instruction-advancer.interface';
import InstructionHalter from '@/interfaces/chat/instruction-halter.interface';
import decodeHistoricAnswer from '@/decoder/history.answer.decoder';

export type MetaData = Record<string, string>;

export default class ChatService implements InstructionAdvancer, InstructionHalter {
    private readonly communitySlug: string;

    private readonly scriptUuid: string;

    private state: Status = Status.Unknown;

    private conversationUuid = null as string | null;

    private metaData = {} as MetaData;

    private eventDispatcher: EventTarget;

    private instructions = [] as InstructionModel[];

    private instructionTimestamp: Date;

    private static minimumInstructionTimeout = 1000;

    private instructionTimeoutId: number | null = null;

    constructor(
        communitySlug: string,
        scriptUuid: string,
        eventDispatcher: EventTarget,
    ) {
        this.communitySlug = communitySlug;
        this.scriptUuid = scriptUuid;
        this.eventDispatcher = eventDispatcher;
        this.instructionTimestamp = new Date();
    }

    public async getState(): Promise<Status> {
        if (this.state === Status.Unknown) {
            this.state = await ConversationApi.GetChatScriptState(this.scriptUuid) as unknown as Status;
        }

        return this.state;
    }

    public async isAvailable(): Promise<boolean> {
        const status = await this.getState();

        return status === Status.Live || status === Status.Preparation;
    }

    public setConversationUuid(uuid: string): void {
        if (this.conversationUuid !== null) {
            throw new Error('Trying to initialize an already initialized conversation');
        }

        this.conversationUuid = uuid;
        this.dispatch('initialized', this.conversationUuid);
    }

    public setMetaData(metaData: MetaData): void {
        this.metaData = metaData;
    }

    private handleBeforeInstruction(instruction: InstructionModel): boolean {
        const options = {cancelable: true};
        const beforeEvents = [
            {
                type: 'before-instruction',
                detail: instruction,
            },
            {
                type: `before-instruction:${instruction.type}`,
                detail: instruction,
            },
            {
                type: 'before-question',
                detail: instruction.question,
            },
            {
                type: `before-question:${instruction.question?.type}`,
                detail: instruction.question,
            },
            {
                type: 'before-action',
                detail: instruction.action,
            },
            {
                type: `before-action:${instruction.action?.type}`,
                detail: instruction.action,
            },
        ].filter((config) => config.detail);

        return !beforeEvents.reduce(
            (carry: boolean, {type, detail}) => (
                carry || this.dispatch(type, detail, options).defaultPrevented
            ),
            beforeEvents.length === 0,
        );
    }

    private handleInstructionTriggered(instruction: InstructionModel): void {
        this.pushInstruction(instruction);
        this.dispatch('instruction', instruction);
        this.dispatch(`instruction:${instruction.type}`, instruction);

        const {question, action} = instruction;

        if (question) {
            this.dispatch('question', question);
            this.dispatch(`question:${question.type}`, question);
            this.dispatch('question-instruction', instruction);
            this.dispatch(`question-instruction:${question.type}`, instruction);
        }

        if (action) {
            this.dispatch('action', action);
            this.dispatch(`action:${action.type}`, action);
            this.dispatch('action-instruction', instruction);
            this.dispatch(`action-instruction:${action.type}`, instruction);
        }
    }

    private handleInstruction(
        instruction: InstructionModel,
        completed: number,
    ): void {
        // Instruction handling was canceled by a *before* event.
        if (!this.handleBeforeInstruction(instruction)) {
            return;
        }

        this.instructionTimeoutId = window.setTimeout(
            () => {
                this.instructionTimeoutId = null;
                this.dispatch('progress', completed);
                this.handleInstructionTriggered(instruction);
            },
            this.getInstructionTimeout(this.getCurrentInstruction()),
        );
    }

    private pushInstruction(instruction: InstructionModel): void {
        this.deleteInstruction(instruction);
        this.instructions.push(instruction);
        this.dispatch('push-instruction', instruction);
    }

    private pushAnswer(answer: AnswerModel): void {
        this.dispatch('answer', answer);
    }

    private getInstructionTimeout(instruction: InstructionModel|null): number {
        const elapsed = this.timeSinceLastInstruction();
        const timeout = ((instruction?.delay ?? 0) * 1000) - elapsed;

        return Math.max(timeout, ChatService.minimumInstructionTimeout);
    }

    private timeSinceLastInstruction(): number {
        return (new Date()).getTime() - this.instructionTimestamp.getTime();
    }

    public on(type: string, callback: EventListener): void {
        this.eventDispatcher.addEventListener(type, callback);
    }

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    private dispatch(type: string, detail: any = null, options: object = {}): CustomEvent {
        const event = new CustomEvent(type, {...options, detail});
        this.eventDispatcher.dispatchEvent(event);
        return event;
    }

    public startConversation(): void {
        this.dispatch('fetch');
        ConversationService.SetNewInstructionCallback(
            (instruction, completed): void => {
                this.handleInstruction(instruction, completed);
            },
        );
        ConversationService.SetErrorCallback(
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            (error: Record<string, any>, group: string | undefined): void => {
                this.handleError(String(group), error);
            },
        );

        if (this.conversationUuid) {
            ConversationService.ContinueConversation(
                this.communitySlug,
                this.scriptUuid,
                this.conversationUuid,
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                (history: Record<string, any>): void => {
                    this.handleHistory(history);
                },
                this.metaData,
            );
            return;
        }

        ConversationService.StartNewConversation(
            this.communitySlug,
            this.scriptUuid,
            null,
            this.metaData,
        ).then(({conversation, name}) => {
            if (conversation && conversation.uuid) {
                this.setConversationUuid(conversation.uuid);
                this.dispatch('created-at', new Date());
                this.dispatch('name-change', name);
            }
        });
    }

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    private handleHistory(history: Record<string, any>): void {
        this.dispatch('progress', history.completed);
        this.dispatch('created-at', new Date(history.started));
        this.dispatch('name-change', history.title);

        if (history.history.length === 0) {
            this.dispatch('fetch');
            ConversationService.StartConversation(
                this.communitySlug,
                this.scriptUuid,
                String(this.conversationUuid),
            );
            return;
        }

        const instructions = history.history as InstructionModel[];
        const {answers} = history;

        instructions.forEach((instruction: InstructionModel) => {
            this.pushInstruction(instruction);

            const {question} = instruction;

            if (question?.uuid && question.uuid in answers) {
                this.pushAnswer(
                    createAnswer(
                        instruction,
                        decodeHistoricAnswer(question, answers[question.uuid]),
                    ),
                );
            }
        });

        this.halt();
        this.dispatch('history-loaded', history);
    }

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    private handleError(group: string, error: Record<string, any>): void {
        this.halt();

        if (error.response.status === 400) {
            error.response.text().then((body: string) => {
                try {
                    const {message = body}: {message: string} = JSON.parse(body);
                    this.dispatch(`${group}-rejected`, message);
                } catch (JsonError) {
                    this.dispatch(`${group}-rejected`, body);
                }
            });
            return;
        }

        throw error;
    }

    private getCurrentInstruction(): InstructionModel|null {
        return this.instructions[this.instructions.length - 1] ?? null;
    }

    public nextInstruction(instructionUuid?: string): void {
        const {communitySlug, conversationUuid} = this;

        if (conversationUuid === null) {
            throw new Error('Trying to get the next instruction for an uninitialized conversation');
        }

        this.dispatch('fetch', instructionUuid);
        this.instructionTimestamp = new Date();
        ConversationService.GetNextInstruction(
            communitySlug,
            conversationUuid as string,
            (instructionUuid ?? this.getCurrentInstruction()?.uuid) as string,
        );
    }

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    public reply(instruction: InstructionModel, payload: any): Promise<boolean> {
        const {communitySlug, conversationUuid} = this;

        return new Promise((resolve, reject) => {
            if (conversationUuid === null) {
                reject(new Error('Trying to reply to an uninitialized conversation'));
                return;
            }

            this.dispatch('fetch', instruction.uuid);

            if (payload instanceof File) {
                ConversationService.AnswerFileInstruction(
                    communitySlug,
                    conversationUuid,
                    instruction.uuid,
                    payload,
                ).then(resolve);
                return;
            }

            ConversationService.AnswerInstruction(
                communitySlug,
                conversationUuid,
                instruction.uuid,
                encodePayload(instruction, payload),
            ).then(resolve);
        });
    }

    private getQuestionInstructions(): InstructionModel[] {
        return this.instructions.filter(
            (instruction: InstructionModel) => instruction.question,
        );
    }

    public canRevert(currentInstruction: InstructionModel): boolean {
        const previousQuestions = this.getQuestionInstructions().filter(
            (instruction: InstructionModel) => instruction.uuid !== currentInstruction.uuid,
        ).reverse();

        return previousQuestions[0]?.can_revert ?? false;
    }

    public revert(currentInstruction: InstructionModel): Promise<InstructionModel> {
        return new Promise((resolve, reject) => {
            if (!this.canRevert(currentInstruction)) {
                reject(new Error(
                    `Cannot revert to the question before instruction ${currentInstruction.uuid}`,
                ));
                return;
            }

            // Prevent new instructions from being triggered during a revert.
            if (this.instructionTimeoutId) {
                window.clearTimeout(this.instructionTimeoutId);
                this.instructionTimeoutId = null;
            }

            this.dispatch('fetch');

            ConversationService.RevertToLastQuestion(
                this.communitySlug,
                this.conversationUuid ?? '',
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            ).then((response: Record<string, any> | boolean | undefined) => {
                if (typeof response !== 'object') {
                    reject(new Error('Server responded with unexpected result'));
                    return;
                }

                if (!('instruction' in response)) {
                    reject(new Error('Missing instruction on server response'));
                    return;
                }

                const {instruction} = response;
                const instructions = [...this.instructions].reverse();
                const index = instructions.findIndex(
                    (candidate) => candidate.uuid === instruction.uuid,
                );

                if (index < 0) {
                    reject(new Error(
                        `Could not find reverted instruction ${instruction.uuid} in local dataset`,
                    ));
                    return;
                }

                instructions.slice(0, index).forEach((revert) => {
                    this.deleteInstruction(revert);
                });

                this.dispatch('reverted', instruction);
                this.halt();
            });
        });
    }

    private deleteInstruction(instruction: InstructionModel): void {
        const match = this.instructions.find(
            (candidate) => candidate.uuid === instruction.uuid,
        );

        if (!match) {
            return;
        }

        this.dispatch('delete-instruction', instruction);

        this.instructions = this.instructions.filter(
            (candidate) => candidate.uuid !== instruction.uuid,
        );

        this.dispatch('deleted-instruction', instruction);
    }

    public halt(): void {
        this.dispatch('halt');

        if (this.instructionTimeoutId) {
            clearTimeout(this.instructionTimeoutId);
            this.instructionTimeoutId = null;
        }

        this.dispatch('halted');
    }
}
