import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Preview from '@/views/Preview.vue';
import Chat from '@/views/Chat.vue';
import Closed from '@/views/Closed.vue';
import ShortCode from '@/views/ShortCode.vue';
import ConversationLoader from '@/views/ConversationLoader.vue';
import Dev from '@/views/Dev.vue';
import Icons from '@/components/dev/Icons.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/:community/preview',
        name: 'Preview',
        component: Preview,
    },
    {
        path: '/:community/script/:script/conversation/:conversation',
        name: 'ConversationLoader',
        component: ConversationLoader,
    },
    {
        path: '/s/:shortCode',
        name: 'ShortCode',
        component: ShortCode,
    },
    {
        path: '/:community/chat/:script',
        name: 'Chat',
        component: Chat,
    },
    {
        path: '/:community/chat/:script/anonymous',
        name: 'AnonymousChat',
        component: Chat,
    },
    {
        path: '/chat/closed',
        name: 'Closed',
        component: Closed,
    },
];

const devRoutes: Array<RouteConfig> = [
    {
        path: '/_dev',
        name: 'Development',
        component: Dev,
        children: [
            {
                path: 'icons',
                component: Icons,
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: process.env.NODE_ENV === 'development'
        ? [...routes, ...devRoutes]
        : [...routes],
});

export default router;
