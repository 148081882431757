import InstructionModel from '@/models/instruction.model';
import QuestionModel, {QuestionType} from '@/models/question.model';
import ChoiceModel from '@/models/choice.model';
import {currencies} from '@/helpers/currencies';

const numberExtractor = (question: QuestionModel, formData: FormData): number => (
    Number(formData.get(question.type ?? ''))
);

const stringExtractor = (question: QuestionModel, formData: FormData): string => (
    String(formData.get(question.type ?? ''))
);

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type QuestionExtractor = (question: QuestionModel, formData: FormData) => any;

const questionExtractors: Record<QuestionType, QuestionExtractor> = {
    date(question: QuestionModel, formData: FormData): string {
        return (new Date(
            formData.get(question.type ?? '')?.toString() ?? '',
        )).toISOString();
    },
    multiple(question: QuestionModel, formData: FormData): Record<string, boolean> {
        return (question.choices ?? []).reduce(
            (carry: Record<string, boolean>, choice: ChoiceModel) => ({
                ...carry,
                [choice.uuid]: formData.get(`multiple-${choice.uuid}`) !== null,
            }),
            {},
        );
    },
    file(question: QuestionModel, formData: FormData): File {
        return formData.get(question.type ?? '') as File;
    },
    open: stringExtractor,
    email: stringExtractor,
    numeric: numberExtractor,
    nps: numberExtractor,
    emoji: numberExtractor,
    choice: numberExtractor,
    currency(question: QuestionModel, formData: FormData): string {
        const index = question.options.currency as keyof typeof currencies;
        const currency = currencies[index] ?? '';
        return `${currency} ${formData.get(question.type ?? '')}`.trim();
    },
    starRating: numberExtractor,
};

export default function extractAnswer(
    instruction: InstructionModel,
    formData: FormData,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
): any {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    let answerDto: any = null;
    const {action, question} = instruction;

    if (action && formData.has(action.type)) {
        answerDto = formData.get(action.type);
    }

    if (question?.type && question.type in questionExtractors) {
        answerDto = questionExtractors[question.type](question, formData);
    }

    return answerDto;
}
